<template>
  <b-card>
    <FormBuilder
      label="Banner"
      update
      :data="$store.state.banner.detailData"
      :fields="fields"
      :loading-detail="$store.state.banner.loadingDetail"
      :loading-submit="$store.state.banner.loadingSubmit"
      @load="loadData"
      @submit="submitData"
      @reset="resetData"
    />
  </b-card>
</template>

<script>
import FormBuilder from '@/layouts/components/FormBuilder.vue'

import {
  BCard,
} from 'bootstrap-vue'

import fields from '@/field/form/banner'

export default {
  components: {
    BCard,
    FormBuilder,
  },
  setup() {
    return {
      fields,
    }
  },
  methods: {
    loadData(id) {
      return this.$store.dispatch('banner/getDetail', id)
    },
    submitData(id, data, callback) {
      this.$store.dispatch('banner/update', { id, data })
        .then(callback)
    },
    resetData() {
      return this.$store.commit('banner/SET_DETAIL_DATA', undefined)
    },
  },
}
</script>

<style scoped>

</style>
